/* .filepond--drop-label {
    margin-top: auto;
    width: 100%;
    height: 100%;  
    font-size: 100px;
} */

.filepond--drop-label label {
  font-size: 15px;
}

.filepond--file-wrapper {
  font-size: 15px !important;
}
