/*@tailwind base;*/
@import "rsuite.css";
@import "sortable-tree.css";

/**
 * Custom base styles
 */

:root {
  --drawer-background-color: /*default background color here*/ ;
  --drawer-color-text-title: /*default background color here*/ ;
  --filepond-bg-color: /*default background color here*/ ;
}

.rs-drawer-content {
  background-color: var(--drawer-background-color);
}

.rs-drawer-header {
  border-bottom: 1px solid #7d7d7d4a;
}

.rs-drawer-title {
  color: var(--drawer-color-text-title);
}

* {
  /* Text rendering */
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

* :focus {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: "Poppins", Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #121212;
}

body {
  font-size: 14px;
  line-height: 1.4;
  overflow-x: hidden;
  font-feature-settings: "salt";
}

html,
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

html,
body {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  min-height: 100%;
  width: 100%;
  flex: 1 1 auto;
}

#root {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

[role="tooltip"] {
  z-index: 9999;
}

.MuiModal-root {
  z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  animation: none !important;
}

button:focus {
  outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transitiondelay: 9999s;
  transitionproperty: background-color, color;
}

:focus {
  outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.MuiAutocomplete-popper {
  z-index: 999999999;
}

.FusePageSimple-sidebarContent {
  height: 100% !important;
}

@media only screen and (max-width: 600px) {
  .rs-drawer-right.rs-drawer-sm {
    width: 100% !important;
  }
}

textarea {
  font-family: "Poppins";
}

/* .MuiTableCell-sizeMedium {
  background: #fff;
}

.MuiToolbar-gutters {
  background: #fff;
  border-radius: 14px;
} */

.rst__moveHandle {
  border-radius: 16px 0px 0px 16px;
  -webkit-border-radius: 16px 0px 0px 16px;
  -moz-border-radius: 16px 0px 0px 16px;
  box-shadow: none;
}

.rst__rowContents {
  border-radius: 0px 16px 16px 0px;
  -webkit-border-radius: 0px 16px 16px 0px;
  -moz-border-radius: 0px 16px 16px 0px;
  box-shadow: none;
}

.rst__row {
  border-radius: 16px 0px 0px 16px;
  -webkit-border-radius: 16px 0px 0px 16px;
  -moz-border-radius: 16px 0px 0px 16px;
  box-shadow: none;
}

.rst__placeholder::before {
  border: 2px dashed #d9d9d9;
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  z-index: -1;
  border-radius: 16px 16px 16px 16px !important;
  -webkit-border-radius: 16px 16px 16px 16px !important;
  -moz-border-radius: 16px 16px 16px 16px !important;
}

.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: #b4d7e9;
  border: 2px dashed white;
  border-radius: 20px;
}

.rst__rowSearchMatch {
  border-radius: 16px 16px 16px 16px !important;
  -webkit-border-radius: 16px 16px 16px 16px !important;
  -moz-border-radius: 16px 16px 16px 16px !important;
  outline: solid 3px #a3a3a3;
}

.rst__rowSearchFocus {
  outline: solid 3px #ff5656;
}

/* .container{
   max-width: 100% !important; 
} */
