.filepond--file-info {
  transform: none !important;
}

.filepond--file-info-main {
  white-space: normal !important;
}

.filepond--panel-root {
  background-color: var(--filepond-bg-color) !important;
}

.filepond--drop-label {
  color: var(--drawer-color-text-title) !important;
}
