.btnlock {
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  display: inline-block;
  background: #20cca5;
  /* background: #ff5b5b; */
  width: 250px;
  height: 250px;
  box-sizing: border-box;
  padding: 8% 0 0 17.6%;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.btnlock svg {
  fill: none;
  transform: translate3d(0, 0, 0);
}

.btnlock svg .bling {
  stroke: #fff;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-dasharray: 3;
  stroke-dashoffset: 15;
  transition: all 0.3s ease;
}

.btnlock svg .lock {
  stroke: #fff;
  stroke-width: 4;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 36;
  transition: all 0.4s ease;
}

.btnlock svg .lockb {
  fill: #fff;
  fill-rule: evenodd;
  clip-rule: evenodd;
  transform: rotate(8deg);
  transform-origin: 14px 20px;
  transition: all 0.2s ease;
}

.inpLock {
  display: none;
}

.inpLock:checked + label {
  background: #ff5b5b;
  /* background: #20cca5; */
}

.inpLock:checked + label svg {
  opacity: 1;
}

.inpLock:checked + label svg .bling {
  animation: bling6132 0.3s linear forwards;
  animation-delay: 0.2s;
}

.inpLock:checked + label svg .lock {
  stroke-dasharray: 48;
  animation: locked 0.3s linear forwards;
}

.inpLock:checked + label svg .lockb {
  transform: rotate(0);
  transform-origin: 14px 22px;
}

@keyframes bling6132 {
  50% {
    stroke-dasharray: 3;
    stroke-dashoffset: 12;
  }

  100% {
    stroke-dasharray: 3;
    stroke-dashoffset: 9;
  }
}

@keyframes locked {
  50% {
    transform: translateY(1px);
  }
}
